@import "src/helpers/styles/themes";
.header{
  background: $dark;
  position: sticky;
  top: 0;
  z-index: 99;
  .header-in{
    width: calc(100% - 24px);
    max-width: 1140px;
    padding: 12px;

    .menu-content{
      position: fixed;
      left: -224px;
      top: 60px;
      background: $dark;
      width: 200px;
      height: calc(100% - 84px);
      padding: 12px;

      &.opened{
        left: 0;
      }

    }

  }
}