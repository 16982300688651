@import "src/helpers/styles/themes";

.select {
  position: relative;
  border-radius: 4px;
  background-color: $grey-light;
  width: 292px;
  max-width: 100%;


  .select-title-content {
    display: flex;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
    width: 280px;
    max-width: calc(100% - 12px);
    height: 32px;
    line-height: 16px;
    font-size: 14px;
    padding-right: 12px;


    .select-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 20px;
      padding: 0 0 0 12px;
      font-weight: 400;

    }

    .select-icon {
      display: flex;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;

      svg path {
        fill: $grey;

      }
    }
  }

  &.empty .select-title {
    color: $grey;
  }

  &.mini {
    .select-title-content, .select-list {
      width: 100px;
    }
  }

  &.middle {
    .select-title-content, .select-list {
      width: 120px;
    }
  }

  &:not(.opened) {
    .select-title-content {
      &:hover {
        background: $grey-light;
      }
    }
  }

}

.select-list {
  flex-direction: column;
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.20);
  border-radius: 0 0 4px 4px;
  padding: 6px 0;
  margin-left: -1px;
  z-index: 999;


  .select-list-item {
    padding: 6px;
    cursor: pointer;
    color: $dark;


    &.selected {
      background-color: rgba($main, .2);
    }

    &:hover {
      color: $light;
      background: $main;
    }
  }
}