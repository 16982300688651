@import "./../../helpers/styles/themes";
.space{
  flex-shrink: 0;
  &:not(.vertical) {
    &.size-sm {
      width: 12px;
    }

    &.size-xmd {
      width: 20px;
    }

    &.size-md {
      width: 24px;
    }

    &.size-lg {
      width: 48px;
    }

    &.size-xlg {
      width: 72px;
    }

    &.size-xsm {
      width: 6px;
    }


    &.size-auto {
      width: 100%;
      flex-shrink: unset;
    }
  }
  &.line{
    border-bottom: 1px solid $grey-light;
  }
  &.vertical{
    &.size-sm {
      height: 12px;
    }
    &.size-xmd {
      height: 20px;
    }
    &.size-md {
      height: 24px;
    }

    &.size-lg {
      height: 48px;
    }

    &.size-xlg {
      height: 72px;
    }

    &.size-xsm {
      height: 6px;
    }
    &.size-xxl {
      height: 98px;
    }
    &.size-auto {
      height: 100%;
    }
  }

}