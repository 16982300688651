@import "./src/helpers/styles/themes";

.list-item {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 4px;
  //sizes
  &.size-md {
    padding: 6px 0;
    min-height: 40px;
    height: 40px;

    .list-item-icon {
      width: 24px;
      height: 24px;
      img{
        width: 32px;
        height: 32px;
      }
      svg {
        path:not([fill='none']) {
          fill: $dark;
        }
      }
      &.list-item-icon-left {
        margin-left: 6px;
      }

      &.list-item-icon-right {
        margin-right: 6px;
      }
    }
  }

  &.size-lg {

    .list-item-icon {

      img{
        margin: 4px;
        width: 56px;
        height: 56px;
        object-fit: cover;
        border-radius: 4px;
      }

      &.list-item-icon-left {
        margin-left: 6px;
      }

      &.list-item-icon-right {
        margin-right: 6px;
      }
    }
  }

  .list-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    img{
      margin: 4px;
      width: 56px;
      height: 56px;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  //hover
  &.hover {
    cursor: pointer;

    &:hover {
      background: rgba($dark, 0.1);
    }
  }

  &.hovered {
    background: rgba($dark, 0.1);
  }

  &.selected {
    background: rgba($main, .1);
  }


  .list-item-title {
    padding: 0 6px;
    width: 100%;
    color: rgba($dark, 1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


}