.preloader{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("../../../public/load.gif") no-repeat;
  background-position: center;
  background-size: 180px;
  background-color: rgba(255,255,255,0.8);
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}