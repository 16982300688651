$input_blue: #2977ff;
$input_grey: #ece9e9;
$input_grey2: #7b7b7b;
.Editor{
  width: calc(100% - 2px);
  display: flex;
  flex-direction: column;
  background: white;
  &.full{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: calc(100% - 12px);
    z-index: 999;
    width: calc(100% - 12px);
    margin: auto;
    .editor-content {
      height: 100%;
      width: 100%;
      overflow: hidden;
      .editor-in {
        height: 100%;
        width: calc(100% - 14px);
      }
    }
  }

  &.size-sm{
    .editor-content .editor-in{
      height: 92px;
    }
  }

  .editor-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    .editor-link-preview {
        position: absolute;
      left: 2px;
      bottom: 2px;
      background: rgba(0,0,0,0.65);
      padding: 2px 4px;
      color: white;
      border-radius: 4px;
    }

    .image-editor{
      width: 100%;
      position: absolute;
      background: white;
      padding: 12px;
      border: 1px solid $input_grey;
      top:  0;
      .input-in{
        width: 90px;
      }
    }

    .editor-in {
      width: calc(100% - 12px);
      height: 288px;
      padding: 6px;
      border: 1px solid $input_grey;
      border-radius: 0 0 4px 4px;
      line-height: 22px;
      font-size: 16px;
      overflow-y: auto;

      .youtube-player{
        display: block;
        margin: 4px auto;
      }
      .editor-img-content{
       display: inline-block;
        width: fit-content;
        position: relative;
        max-width: 100%;
        &[float=right]{
          float: right;
          margin-left: 20px;
          max-width: calc(100% - 20px);
        }
        &[float=left]{
          float: left;
          margin-right: 20px;
          max-width: calc(100% - 20px);
          clear: both;
        }
        &[align=center]{
          width: 100%;
          text-align: center;
          .editor-img-content-in{
            margin-left: auto;
            margin-right: auto;
          }
        }
        &[align=right]{
          width: 100%;
          .editor-img-content-in{
            margin-left: auto;
          }
        }
       .editor-img-content-in{
         width: fit-content;
         max-width: 100%;
       }
        .editor-image-caption{
          font-size: 14px;
          color: $input_grey2;
          text-align: center;
          pointer-events: none;
          max-width: 100%;
          display: none;
        }
        img{
          pointer-events: none;
          max-width: 100%;
          display: block;
          cursor: pointer;
          &[align=right]{
            margin-left: 20px;
          }
          &[align=left]{
            margin-right: 20px;
          }
          &.center-img{
            margin: 4px auto;
          }
          &.right-img{
            margin: 4px 4px 4px auto;
          }
        }
      }


      .fake-selection {
        background: $input_blue;
        color: white;
      }

      &:focus {
        outline: none;
        border-color: $input_blue;
      }

      a {
        color: $input_blue;
      }

      font[size="1"] {
        font-size: 12px;
      }

      font[size="2"] {
        font-size: 14px;
      }

      font[size="3"] {
        font-size: 16px;
      }

      font[size="4"] {
        font-size: 18px;
      }
    }
  }

    .editor-toolbar {
      border: 1px solid $input_grey;
      border-bottom: none;
      padding: 4px;
      border-radius: 4px 4px 0 0;
      width: calc(100% - 8px);
      flex-wrap: wrap;
      position: relative;
      .toolbar-button {
        border: 1px solid $input_grey;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        cursor: pointer;
        border-radius: 4px;
        position: relative;
        &.fullscreen-button{
          margin-left: auto;
        }
        &:hover {
          opacity: .8;

          &:not(.hovered) {
            background: $input_grey;
          }
        }


        &.bold-button {
          font-weight: 700;
        }

        &.italic-button {
          font-style: italic;
        }

        &.underline-button {
          text-decoration: underline;
        }

        &.font-size-button {
          font-size: 14px;
          font-weight: 500;
        }

        &.hovered {
          background: rgba($input_blue, 0.2);
          color: $input_blue;
          border-color: rgba($input_blue, 0.2);
        }

      }

      .editor-picker {
        width: 92px;
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        background: white;
        border: 1px solid $input_grey2;
        border-radius: 4px;
        margin-top: 2px;
        padding: 4px;
        gap: 4px;
        z-index: 999;

        .color-item {
          width: 28px;
          height: 28px;
          flex-shrink: 0;
          cursor: pointer;
          border-radius: 4px;

          &:hover {
            opacity: .8;
          }
        }

        .font-size-item {
          width: calc(100% - 4px);
          flex-shrink: 0;
          cursor: pointer;
          padding: 2px;
          border-radius: 4px;

          &:hover {
            opacity: .8;
            background: $input_grey;
          }
        }
      }
    }
  }

.media-uploader{
  .media-uploader-content{
    .preview-image{
      position: relative;
      cursor: pointer;

      .media-delete{
        position: absolute;
        border-radius: 4px;
        right: 0;
        top: 0;
        display: none;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
          fill: white;
        }
        &:hover{
          svg{
            fill: red;
          }
        }
      }
      &:hover{
        opacity: .8;
        .media-delete{
          display: flex;

        }
      }
      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }
}