@import "./../../helpers/styles/themes";

a >.text:hover{
  color: $main;
}

.text{
  font-family: Mardoto, system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $dark;

  &.padding-default{
    padding: 0;
  }
  &.padding-xsm{
    padding: 0 4px;
  }
  &.padding-sm{
    padding: 0 12px;
  }
  &.padding-md{
    padding: 0 24px;
  }

  &.wrap-pre{
    white-space: pre-wrap;
  }

  &.line-through{
    text-decoration: line-through;
  }

  &.single-line{
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &.center{
    text-align: center;
    display: block;
  }

  &.right{ text-align: right }

  &.b{
    font-weight: 700;
  }

  &.m{ font-weight: 500 }

  &.sm{
    font-size: 12px;
    line-height: 16px;
  }
  &.xsm{
    font-size: 14px;
    line-height: 16px;
  }
  &.xmd{
    font-size: 16px;
    line-height: 22px;
  }

  &.md{
    font-size: 20px;
    line-height: 26px;
  }
  &.lm{
    font-size: 40px;
    line-height: 56px;
  }
  &.lg{
    font-size: 64px;
    line-height: 70px;
  }
  &.grey-light{
    color: rgba($dark, 0.6);
  }
  &.grey-middle{
    color: rgba($dark, 0.8);
  }
  &.main{color: $main;}
  &.red{color: $red;}
  &.dark{color: $dark;}
  &.light{color: $light;}
  &.grey{color:$grey;}
}
/*@media only screen and (max-width: 768px) {
  .text{

    &.sm{
      font-size: 12px;
      line-height: 16px;
    }
    &.xmd{
      font-size: 15px;
      line-height: 22px;
    }

    &.md{
      font-size: 16px;
      line-height: 26px;
    }
    &.lm{
      font-size: 18px;
      line-height: 30px;
    }
    &.lg{
      font-size: 24px;
      line-height: 30px;
    }

  }


}
@media only screen and (max-width: 412px) {
  .text{

    &.sm{
      font-size: 10px;
      line-height: 16px;
    }
    &.xmd{
      font-size: 15px;
      line-height: 22px;
    }

    &.md{
      font-size: 15px;
      line-height: 26px;
    }
    &.lm{
      font-size: 16px;
      line-height: 30px;
    }
    &.lg{
      font-size: 18px;
      line-height: 30px;
    }

  }


}*/

