body {
  font-family: Mardoto system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  a {
    text-decoration: none;
    display: contents;
    color: #232323;
  }
  .hidden{
    display: none;
  }
}
