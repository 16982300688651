@import "src/helpers/styles/themes";
.uploader{
  width: 320px;
  max-width: 100%;
  height: calc(100vw - 24px);
  max-height: 320px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
  background: rgba($dark, 0.1);

  &.size-s{
    width: 72px;
    height: 72px;
  }
  &.size-l{
    width: 100%;
    height: calc((100vw - 24px) / 1.77);
    max-height: 644px;
  }

  &.disabled{
    pointer-events: none;
    opacity: .5;
  }

  &:hover{
    opacity: .8;
  }

  svg{
    width: 48px;
    height: 48px;
    path{
      fill: rgba($dark, 0.1);
    }
  }

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .upload-cover{
    width: 72px;
    height: 72px;
    background-position: center;
    background-repeat: no-repeat;
  }

  input{
    display: none;
  }
  .progress-bar{
    width: 0%;
    transition: .5s width;
    height: 100%;
    background: rgba($main, 0.5);
    position: absolute;
    left: 0;
    bottom: 0;
  }
}