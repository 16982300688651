@import "./src/helpers/styles/themes";

#context-content {
  .context-menu-cover {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 3400;
    animation-name: contextOpen;
    animation-duration: .01s;
    @media only screen and (max-width: 768px){
      background-color: rgba(0,0,0,0.7);
    }
    .context-menu {
      position: fixed;
      width: 292px;
      border-radius: 4px;
      padding: 6px 0;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.20);
      background: white;



      @media only screen and (max-width: 768px){

        @keyframes contextMobile {
          from{bottom: -100px; opacity: .5}
          to{bottom: 0; opacity: 1}
        }

        width: 100% !important;
        max-height: calc(100% - 60px);
        left: 0 !important;
        bottom: 0 ;
        top: unset !important;
        min-height: 280px;
        animation-name: contextMobile;
        animation-duration: .2s;
      }

      &.size-sm{
        width: 230px;
      }



      .context-content{
        max-height: 320px;
        overflow: hidden;
        overflow-y: overlay;
        @media only screen and (max-width: 768px){
          max-height: calc(100vh - 80px);
        }
        .list-item{
          border-radius: 0;
        }

        /* width */
        &::-webkit-scrollbar {
          width: 0px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: transparent;

        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          opacity: 0;
        }

        &:hover{
          /* width */
          &::-webkit-scrollbar {
            width: 3px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: rgba($dark, 0.05);
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: rgba($dark, 0.1);
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            opacity: .7;
          }
        }
      }

    }
  }
}

@keyframes contextOpen {
  from {opacity: 0}
  99% { opacity: 0}
  to {opacity: 1}
}