@import "./../../helpers/styles/themes";

.button {
  display: flex;
  background: $main;
  align-items: center;
  width: fit-content;
  flex-shrink: 0;
  height: 36px;
  min-width: 36px;
  border-radius: 6px;
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  //stretch
  &.stretch {
    width: 100%;
    flex-shrink: unset;
  }

  //Loading

  &.loading{

    &:after{
      content: "";
      position: absolute;
      width: 80px;
      height: 100%;
      left: -80px;
      top: 0;
      filter: blur(20px);
      background: rgba(255,255,255, .2);
      animation-name: loading;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }
    pointer-events: none;
  }

  @keyframes loading {
    from {left: -80px}
    to{left: 100%}
  }

  //stretch
  &.fixed-w {
    width: 200px;
  }

  //disabled

  &.disabled{
    opacity: .6;
    pointer-events: none;
  }

  //title
  .button-title-content {
    width: 100%;
    overflow: hidden;

    .button-title {
      width: 100%;
      color: $light;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .button-title-description {
      width: 100%;
      color: rgba($light, 0.8);
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }


  &.p-left {
    padding-left: 12px;
  }

  &.p-right {
    padding-right: 12px;
  }

  .button-icon {
    width: 36px;
    height: 36px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;

      path:not([fill=none]), rect, strike {
        fill: $light;
      }
    }
  }

  // sizes ////

  //size-xsm
  &.size-xsm {
    height: 28px;
    min-width: 28px;
    //title
    .button-title-content {
      align-items: center;
      display: flex;
      .button-title {
      }

      .button-title-description {
        font-size: 11px;
        line-height: 20px;
      }
    }

    &.p-left {
      padding-left: 8px;
    }

    &.p-right {
      padding-right: 8px;
    }

    .button-icon {
      width: 28px;
      height: 28px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  //size-md
  &.size-md {
    height: 52px;
    //title
    .button-title-content {
      .button-title {
        font-size: 16px;
        line-height: 22px;
      }

      .button-title-description {
        font-size: 14px;
        line-height: 16px;
      }
    }

    &.p-left {
      padding-left: 16px;
    }

    &.p-right {
      padding-right: 16px;
    }

    .button-icon {
      width: 52px;
      height: 52px;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  &.hover-button {
    cursor: pointer;

    &:hover {
      opacity: .9;
    }

    &:active {
      opacity: .5;
    }
  }

  //color
  &.color-transparent {
    background: unset;

    &.hover:hover{
      background: rgba($dark, .1);
    }

    svg path:not([fill=none]), svg rect {
      fill: rgba($dark, 1);
    }

    .button-title-content {
      .button-title {
        color: rgba($dark, 1);
      }

      .button-title-description {
        background: rgba($dark, .6);
      }
    }
  }

  &.color-red {
    background: $red;

    .button-icon {
      svg path:not([fill=none]), svg rect {
        fill: rgba($light, 1) !important;
      }
    }
  }
  &.color-green {
    background: $green;
    .button-icon {
      svg path:not([fill=none]), svg rect {
        fill: rgba($light, 1) !important;
      }
    }
  }

  &.color-grey {
    background: $grey-light;

    svg path {
      fill: rgba($dark, .6);
    }

    .button-title-content {
      .button-title {
        color: rgba($dark, 1);
      }

      .button-title-description {
        background: rgba($dark, .8);
      }
    }
  }

}

