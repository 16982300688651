$main: #2893ff;
$dark: #232323;
$light: white;
$white:#fff;
$blue: #033d79;
$red: #EF1E3B;
$green: #009E5A;
$grey:#BEBEBE;
$grey-light: #F3F3F3;


